import { getDB } from "./db";
export async function storeControlOrdersForApp(appId, controlOrderList) {
    console.time('storeControlOrdersForApp');
    console.info(`[PouchDB] store controlorders for app ${appId}`);
    console.group();
    const db = await getDB('controlOrder');
    try {
        let oldDoc;
        try {
            oldDoc = await db.get(appId);
        }
        catch (_a) {
            oldDoc = undefined;
        }
        await db.put({
            ...oldDoc,
            _id: appId,
            ctrlOrder: controlOrderList,
        });
        console.timeEnd('storeControlOrdersForApp');
        console.groupEnd();
    }
    catch (err) {
        console.timeEnd('storeControlOrdersForApp');
        console.groupEnd();
    }
}
export async function getControlOrdersForApp(appId) {
    console.time('getControlOrdersForApp');
    console.info(`[PouchDB] get controlorders for app ${appId}`);
    console.group();
    const db = await getDB('controlOrder');
    try {
        const res = (await db.get(appId)).ctrlOrder;
        console.timeEnd('getControlOrdersForApp');
        console.groupEnd();
        return res;
    }
    catch (_a) {
        console.timeEnd('getControlOrdersForApp');
        console.groupEnd();
        return undefined;
    }
}
