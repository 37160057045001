import { removeRecordsForApp } from '@/services/db/records';
import { alertController, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonPage, IonReorder, IonReorderGroup, IonTitle, IonToolbar, IonToggle, loadingController, onIonViewDidEnter, } from '@ionic/vue';
import { arrowBack } from 'ionicons/icons';
import { computed, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { getApp } from '../services/db/apps';
import { getControlOrdersForApp, storeControlOrdersForApp } from '../services/db/controlorder';
import { AppDoesNotExistError } from '../utils/errors';
import { filterControls } from '../utils/utils';
export default defineComponent({
    components: {
        IonButton,
        IonButtons,
        IonCard,
        IonCardContent,
        IonCardHeader,
        IonCardTitle,
        IonContent,
        IonHeader,
        IonIcon,
        IonItem,
        IonLabel,
        IonPage,
        IonReorder,
        IonReorderGroup,
        IonTitle,
        IonToolbar,
        IonToggle,
    },
    props: {
        appId: String,
    },
    setup(props) {
        const router = useRouter();
        const app = ref();
        const controlOrders = ref();
        const sortedControls = computed(() => app.value && controlOrders.value
            ? filterControls(app.value, controlOrders.value)
            : []);
        onIonViewDidEnter(async () => {
            var _a, _b, _c;
            const loading = await loadingController.create({});
            await loading.present();
            try {
                app.value = (await getApp((_a = props.appId) !== null && _a !== void 0 ? _a : '')).controls;
                controlOrders.value = (_c = (await getControlOrdersForApp((_b = props.appId) !== null && _b !== void 0 ? _b : ''))) !== null && _c !== void 0 ? _c : [];
                await loading.dismiss();
            }
            catch (error) {
                console.error(error);
                await loading.dismiss();
                if (error instanceof AppDoesNotExistError) {
                    const alert = await alertController.create({
                        message: 'App existiert nicht',
                        buttons: [
                            {
                                text: 'Gehe zurück',
                                handler: router.back,
                            },
                        ],
                    });
                    await alert.present();
                }
                else {
                    const alert = await alertController.create({
                        message: 'Ein unbekannter Fehler ist aufgetreten',
                        buttons: [
                            {
                                text: 'Gehe zurück',
                                handler: router.back,
                            }
                        ],
                    });
                    await alert.present();
                }
            }
        });
        async function handleReorder(evt) {
            if (app.value) {
                app.value = evt.detail.complete(app.value);
                const tmpCtrlOrder = app.value.map((appCtrl, index) => {
                    var _a;
                    const ctrlOrderForAppControl = controlOrders.value.find(ctrlO => appCtrl.identifier === ctrlO.ctrlIdentifier);
                    return {
                        ctrlIdentifier: appCtrl.identifier,
                        visible: (_a = ctrlOrderForAppControl === null || ctrlOrderForAppControl === void 0 ? void 0 : ctrlOrderForAppControl.visible) !== null && _a !== void 0 ? _a : true,
                        order: index,
                    };
                });
                controlOrders.value = tmpCtrlOrder;
                if (props.appId) {
                    await storeControlOrdersForApp(props.appId, tmpCtrlOrder);
                }
            }
        }
        async function handleToggle(identifier, checked) {
            if (app.value) {
                const tmpCtrlOrder = app.value
                    .sort((a, b) => {
                    var _a, _b, _c, _d;
                    const ctrlOrderA = (_b = (_a = controlOrders.value.find(ctrlOrder => ctrlOrder.ctrlIdentifier === a.identifier)) === null || _a === void 0 ? void 0 : _a.order) !== null && _b !== void 0 ? _b : 0;
                    const ctrlOrderB = (_d = (_c = controlOrders.value.find(ctrlOrder => ctrlOrder.ctrlIdentifier === b.identifier)) === null || _c === void 0 ? void 0 : _c.order) !== null && _d !== void 0 ? _d : 0;
                    return ctrlOrderA - ctrlOrderB;
                })
                    .map((appCtrl, index) => {
                    var _a;
                    const ctrlOrderForAppControl = controlOrders.value.find(ctrl => appCtrl.identifier === ctrl.ctrlIdentifier);
                    return {
                        ctrlIdentifier: appCtrl.identifier,
                        visible: appCtrl.identifier === identifier
                            ? checked
                            : (_a = ctrlOrderForAppControl === null || ctrlOrderForAppControl === void 0 ? void 0 : ctrlOrderForAppControl.visible) !== null && _a !== void 0 ? _a : true,
                        order: index,
                    };
                });
                controlOrders.value = tmpCtrlOrder;
                if (props.appId) {
                    await storeControlOrdersForApp(props.appId, tmpCtrlOrder);
                }
            }
        }
        async function handleRemoveRecordsForApp() {
            const alert = await alertController.create({
                message: `Die lokal gespeicherten Einträge für diese LivingApp werden gelöscht. Sie werden gleich auf die Appliste umgeleitet.
                        Wenn Sie die LivingApp wieder aufrufen werden alle Daten wieder in den Speicher geladen.
                        Falls Sie viele Datensätze löschen müssen empfiehlt es sich die "alle Einträge löschen" Funktion in den <a href="/settings">Einstellungen</a>
                        zu nutzen, da diese deutlich performanter ist.
                    `,
                buttons: [
                    {
                        text: 'ok',
                        handler: async () => {
                            var _a;
                            const loading = await loadingController.create({
                                message: 'Lösche lokale Daten',
                            });
                            await loading.present();
                            const { error } = await removeRecordsForApp((_a = props.appId) !== null && _a !== void 0 ? _a : '');
                            await loading.dismiss();
                            if (error) {
                                const alert = await alertController.create({
                                    message: 'Es ist ein Fehler augetreten.',
                                    buttons: [
                                        {
                                            text: 'ok',
                                        },
                                    ],
                                });
                                alert.present();
                            }
                            router.push('/');
                        }
                    },
                    {
                        text: 'abbrechen'
                    }
                ]
            });
            await alert.present();
        }
        return {
            arrowBack,
            router,
            handleRemoveRecordsForApp,
            handleReorder,
            handleToggle,
            sortedControls,
        };
    },
});
