export function filterControls(appControls, controlOrderList) {
    return appControls
        .map(ctrl => {
        var _a, _b;
        return ({
            ...ctrl,
            visible: (_b = (_a = controlOrderList.find(ctrlO => ctrlO.ctrlIdentifier === ctrl.identifier)) === null || _a === void 0 ? void 0 : _a.visible) !== null && _b !== void 0 ? _b : true,
        });
    })
        .sort((a, b) => {
        var _a, _b, _c, _d;
        const ctrlOrderA = (_b = (_a = controlOrderList.find(ctrlOrder => ctrlOrder.ctrlIdentifier === a.identifier)) === null || _a === void 0 ? void 0 : _a.order) !== null && _b !== void 0 ? _b : 0;
        const ctrlOrderB = (_d = (_c = controlOrderList.find(ctrlOrder => ctrlOrder.ctrlIdentifier === b.identifier)) === null || _c === void 0 ? void 0 : _c.order) !== null && _d !== void 0 ? _d : 0;
        return ctrlOrderA - ctrlOrderB;
    });
}
