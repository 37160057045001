/* eslint-disable @typescript-eslint/camelcase */
import { getDB } from './db';
import { AppDoesNotExistError } from '../../utils/errors';
export async function storeAppList(appList) {
    console.time('storeAppList');
    console.info('[PouchDB] store applist in local db');
    console.group();
    const db = await getDB('app');
    const allDocs = await db.allDocs({ include_docs: true });
    const oldAppMap = new Map(allDocs.rows.map(row => [row.doc.id, row.doc]));
    const newAppMap = new Map(appList.map(app => [app.id, app]));
    const appsToAdd = [...newAppMap.keys()]
        .filter(id => !oldAppMap.has(id))
        .map(id => ({
        _id: id,
        ...newAppMap.get(id),
    }));
    const appsToDelete = [...oldAppMap.keys()]
        .filter(id => !newAppMap.has(id))
        .map(id => ({
        ...oldAppMap.get(id),
        _deleted: true,
    }));
    const appsToUpdate = [...oldAppMap.keys()]
        .filter(id => newAppMap.has(id))
        .map(id => ({
        ...oldAppMap.get(id),
        ...newAppMap.get(id),
    }));
    db.bulkDocs([
        ...appsToAdd,
        ...appsToDelete,
        ...appsToUpdate,
    ]);
    console.timeEnd('storeAppList');
    console.debug(`[PouchDB] added ${appsToAdd.length}, deleted ${appsToDelete.length}, updated ${appsToUpdate.length} apps in db`);
    console.groupEnd();
}
export async function getAppList() {
    console.time('getAppList');
    console.info('[PouchDB] get applist from local db');
    console.group();
    const db = await getDB('app');
    const allDocs = await db.allDocs({ include_docs: true });
    const allApps = allDocs.rows.map(row => row.doc);
    console.timeEnd('getAppList');
    console.groupEnd();
    return allApps;
}
export async function getApp(appId) {
    console.time('getApp');
    console.info(`[PouchDB] get app from local db`);
    console.group();
    const db = await getDB('app');
    try {
        const doc = await db.get(appId);
        console.timeEnd('getApp');
        console.groupEnd();
        return doc;
    }
    catch (err) {
        console.timeEnd('getApp');
        console.groupEnd();
        throw new AppDoesNotExistError();
    }
}
