/* eslint-disable @typescript-eslint/camelcase */
import { getDB } from "./db";
export async function storeRecordList(appId, recordList) {
    console.time('storeRecordList');
    console.info(`[PouchDB] store recordlist in local db for app ${appId}`);
    console.group();
    const db = await getDB('record');
    const existingDocs = await db.find({
        selector: {
            appId,
        }
    });
    const oldRecMap = new Map(existingDocs.docs.map(doc => [doc.id, doc]));
    const newRecMap = new Map(recordList.map(rec => [rec.id, rec]));
    const recsToAdd = [...newRecMap.keys()]
        .filter(id => !oldRecMap.has(id))
        .map(id => ({
        _id: id,
        appId: newRecMap.get(id).app.id,
        ...newRecMap.get(id),
    }));
    const recsToDelete = [...oldRecMap.keys()]
        .filter(id => !newRecMap.has(id))
        .map(id => ({
        ...oldRecMap.get(id),
        _deleted: true,
    }));
    const recsToUpdate = [...oldRecMap.keys()]
        .filter(id => newRecMap.has(id))
        .map(id => ({
        ...oldRecMap.get(id),
        ...newRecMap.get(id),
    }));
    db.bulkDocs([
        ...recsToAdd,
        ...recsToDelete,
        ...recsToUpdate,
    ]);
    console.timeEnd('storeRecordList');
    console.info(`[PouchDB] created ${recsToAdd.length}, updated ${recsToUpdate.length}, delete ${recsToDelete.length} records`);
    console.groupEnd();
}
export async function getRecordList(appId, offset = 0, limit = 5) {
    console.time('getRecordList');
    console.info('[PouchDB] get recordlist from local db');
    console.group();
    const db = await getDB('record');
    const existingDocs = await db.find({
        selector: {
            appId,
            createDate: {
                $gt: null,
            },
        },
        sort: [
            {
                appId: 'desc',
            },
        ],
        limit,
        skip: offset,
        use_index: ['ci', 'appid'],
    });
    for (const doc of existingDocs.docs) {
        delete doc._id;
        delete doc._rev;
    }
    console.timeEnd('getRecordList');
    console.groupEnd();
    return existingDocs.docs;
}
export async function removeRecordsForApp(appId) {
    console.time('removeRecordsForApp');
    try {
        const db = await getDB('record');
        const existingDocs = await db.find({
            selector: {
                appId,
                createDate: {
                    $gt: null,
                },
            },
            sort: [
                {
                    appId: 'desc',
                },
            ],
            use_index: ['ci', 'appid'],
        });
        ///@ts-ignore
        db.bulkDocs(existingDocs.docs.map(doc => ({
            _id: doc._id,
            _rev: doc._rev,
            _deleted: true,
        })));
        console.timeEnd('removeRecordsForApp');
        console.info(`[PouchDB] removed all records for app ${appId}`);
        return {};
    }
    catch (err) {
        console.timeEnd('removeRecordsForApp');
        console.warn(`[PouchDB] failed all records for app ${appId}`);
        return { error: err };
    }
}
export async function updateRecord(newRecord) {
    console.time('updateRecord');
    console.info(`[PouchDB] update record ${newRecord.id}`);
    console.group();
    try {
        const db = await getDB('record');
        const recordDoc = await db.get(newRecord.id);
        await db.put({
            ...recordDoc,
            ...newRecord,
        });
        console.timeEnd('updateRecord');
        console.groupEnd();
    }
    catch (err) {
        console.timeEnd('updateRecord');
        console.error(`[PouchDB] updateRecord`, err);
        console.groupEnd();
    }
}
export async function storeNewRecord(newRecord) {
    console.time('createRecord');
    console.info(`[PouchDB] create record ${newRecord.id}`);
    console.group();
    try {
        const db = await getDB('record');
        await db.put({
            ...newRecord,
            _id: newRecord.id,
            appId: newRecord.app.id
        });
        console.timeEnd('createRecord');
        console.groupEnd();
    }
    catch (err) {
        console.timeEnd('createRecord');
        console.error(`[PouchDB] createRecord`, err);
        console.groupEnd();
    }
}
export async function deleteRecord(recordId) {
    try {
        console.time('deleteRecord');
        const db = await getDB('record');
        const recordDoc = await db.get(recordId);
        ///@ts-ignore
        await db.put({
            _deleted: true,
            _id: recordDoc._id,
            _rev: recordDoc._rev,
        });
        console.timeEnd('deleteRecord');
        console.info(`[PouchDB] deleted record ${recordId}`);
        return {};
    }
    catch (error) {
        console.timeEnd('deleteRecord');
        return { error };
    }
}
